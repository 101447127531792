<template>
  <div class="home">
    <CommonHeader title="编辑资料" />
    <div class="edit">
      <div class="edit-avatar item-flex-center">
        <van-uploader :after-read="modeUpload">
          <div class="img">
            <img
              :src="avatar"
              alt=""
            >
            <div class="bg item-flex-center">
              <img
                src="https://artimg2.artart.cn/image/20220214/b68ffa0328446661ccbd1f096e80171e.png"
                alt=""
              >
            </div>
          </div>
        </van-uploader>
      </div>
      <div
        class="edit-item flex align-items-c"
        @click="editInfoAction('nickname')"
      >
        <div class="l">昵称</div>
        <div class="c">{{ userName }}</div>
        <div class="r">
          <img
            src="https://artimg2.artart.cn/image/20220214/18fadab7518b23862eb44b75a055107c.png"
            alt=""
          >
        </div>
      </div>
      <div
        class="edit-item flex align-items-c"
        @click="editInfoAction('mark')"
      >
        <div class="l">简介</div>
        <div class="c line1">{{ userMark }}</div>
        <div class="r">
          <img
            src="https://artimg2.artart.cn/image/20220214/18fadab7518b23862eb44b75a055107c.png"
            alt=""
          >
        </div>
      </div>
      <!-- <div
        class="edit-item flex align-items-c"
        @click="goLinkAction(isVerify == 0 ? '/users/apply_verify' : '/users/apply_verify_status')"
      >
        <div class="l">实名认证</div>
        <div class="c"><span v-if="isVerify === 1">已认证</span><span
            v-else-if="isVerify === 0"
            style="color: #666;font-weight: 400;"
          >未认证</span><span
            v-else-if="isVerify === 2"
            style="color: #666;font-weight: 400;"
          >审核中</span><span
            v-else-if="isVerify === -1"
            style="color: #666;font-weight: 400;"
          >已拒绝</span></div>
        <div class="r">
          <img
            src="https://artimg2.artart.cn/image/20220214/18fadab7518b23862eb44b75a055107c.png"
            alt=""
          >
        </div>
      </div> -->
      <div
        class="edit-item flex align-items-c"
        @click="goLinkAction('/users/address_list')"
      >
        <div class="l">我的地址</div>
        <div class="c"></div>
        <div class="r">
          <img
            src="https://artimg2.artart.cn/image/20220214/18fadab7518b23862eb44b75a055107c.png"
            alt=""
          >
        </div>
      </div>
      <!-- <div class="edit-item flex align-items-c">
        <div class="l">我的发票</div>
        <div class="c"></div>
        <div class="r">
          <img
            src="https://artimg2.artart.cn/image/20220214/18fadab7518b23862eb44b75a055107c.png"
            alt=""
          >
        </div>
      </div> -->
    </div>
    <div v-if="isNoXCX" class="logout" :style="leftBtnStyle" @click="logoutAction">退出登录</div>
    <div
      class="input-view"
      v-if="showEditInfo"
    >
      <div class="input-top-menu">
        <div
          class="input-top-l"
          @click="closeEditValueAction"
        >取消</div>
        <div
          class="input-top-r"
          @click="editValueAction"
          :style="'color: ' + colorData.specialColor"
        >保存</div>
      </div>
      <div class="input-bottom">
        <textarea
          v-model="textareaValue"
          :autofocus="true"
          placeholder="请输入"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { applyVerifyInfo, editUserInfo } from "@/api/user";
import { Toast, Uploader } from "vant";
import { getOssUploadConfig , getOss} from "@/api/system";
import { uploadImg, returnWxPayType} from "@/utils/util";
import OSS from "ali-oss";

export default {
  components: {
    [Uploader.name]: Uploader,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isVerify = ref(null);
    const ossClient = ref(null);

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      // applyVerifyInfo().then((res) => {
      //   isVerify.value = res.data ? res.data.verify_status : 0;
      // });
      getOssUploadConfig({ type: 1 })
        .then((res) => {
          ossClient.value = new OSS({
            region: res.data.region,
            accessKeyId: res.data.Credentials.AccessKeyId,
            accessKeySecret: res.data.Credentials.AccessKeySecret,
            stsToken: res.data.Credentials.SecurityToken,
            bucket: res.data.bucket,
          });
        })
        .catch((message) => Toast(message));
    });

    const avatar = computed(() => {
      return store.getters.avatar;
    });
    const userName = computed(() => {
      return store.getters.uname;
    });
    const userMark = computed(() => {
      return store.getters.mark;
    });
    const colorData = computed(() => {
      return store.getters.color_data;
    });
    // 右按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    const showEditInfo = ref(false);
    const textareaValue = ref("");
    let editKey = "";

    const editInfoAction = (key) => {
      editKey = key;
      if (key === "nickname") {
        textareaValue.value = userName.value;
      } else if (key === "mark") {
        textareaValue.value = userMark.value;
      }
      showEditInfo.value = true;
    };

    // 取消修改
    const closeEditValueAction = () => {
      showEditInfo.value = false;
      textareaValue.value = "";
      editKey = "";
    };

    const editAction = (key, value) => {
      editUserInfo({
        type: key,
        data: value,
      })
        .then(() => {
          store.dispatch("user/setUserInfo", {
            key: key == "nickname" ? "uname" : key,
            value: value,
          });
          closeEditValueAction();
        })
        .catch((message) => Toast(message));
    };

    const editValueAction = () => {
      if (!textareaValue.value) {
        closeEditValueAction();
        return false;
      }
      editAction(editKey, textareaValue.value);
    };

    const modeUpload = (file) => {
      const f = file.file;
      if (f.type.indexOf("image") === -1) {
        return;
      }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      uploadImg(f, ossClient, (result) => {
        Toast.clear();
        if (result.data.status == 200) {
          editAction("avatar", result.data.data.image_url.replace(/^https:\/\/cdn.weipai.shop/, "https://artimg2.artart.cn"));
        } else {
          Toast(result.data.message);
        }
      });
    };

    const goLinkAction = (link) => {
      router.push(link);
    };

    const logoutAction = () => {
      store.dispatch('user/resetToken');
      router.push('/');
    }

    return {
      colorData,
      avatar,
      userName,
      userMark,
      isVerify,
      showEditInfo,
      textareaValue,
      leftBtnStyle,
      isNoXCX: returnWxPayType() == "wechat_h5",

      editInfoAction,
      closeEditValueAction,
      editValueAction,
      modeUpload,
      goLinkAction,
      logoutAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  .edit {
    background: #fff;
    .edit-avatar {
      padding: 64px 0;
      height: 180px;

      .img {
        width: 180px;
        height: 180px;
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        img {
          width: 180px;
          height: 180px;
          display: block;
        }

        .bg {
          width: 180px;
          height: 180px;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);

          img {
            width: 48px;
            height: 48px;
          }
        }
      }
    }

    .edit-item {
      padding: 0 32px;
      height: 120px;
      box-shadow: 0px 2px 0px 0px #eeeeee;
      font-size: 28px;
      .l {
        width: 192px;
        color: #666;
      }
      .c {
        flex: 1;
        color: #000;
        font-weight: 500;
      }
      .r {
        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }
    }
  }
}

.input-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  .input-top-menu {
    height: 90px;
    display: flex;
    justify-content: space-between;
  }
  .input-top-l {
    padding: 0 30px;
    font-size: 30px;
    height: 90px;
    line-height: 90px;
  }
  .input-top-r {
    padding: 0 30px;
    font-size: 30px;
    height: 90px;
    line-height: 90px;
  }

  .input-bottom {
    border-top: 15px solid #f7f7f7;
    padding: 30px;
    flex: 1;
  }
  .input-bottom textarea {
    width: 100%;
    height: 50%;
    font-size: 26px;
    border: none;
  }
}

.logout{
  margin: 100px 36px 0 36px !important;
  text-align: center;
  line-height: 80px;
}
</style>